import * as CryptoJS from 'crypto-js';

export class EncryptService {
  private key = CryptoJS.enc.Utf8.parse('1234567890123456'); // 16 bytes key
  private iv = CryptoJS.enc.Utf8.parse('1234567890123456'); // 16 bytes IV
  

  encrypt(data: any): string {     // Encrypt the data and return it as a string
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), this.key, { iv: this.iv }).toString();  
    return encrypted; 
}

  decrypt(encryptedText: string): string {
    const decrypted = CryptoJS.AES.decrypt(encryptedText, this.key, {
      keySize: 128 / 8,
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return CryptoJS.enc.Utf8.stringify(decrypted);
  }
}